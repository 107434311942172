import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { editAlarmSuccess, clearEditAlarm, loadAssetById, clearAssetById } from './assets.actions';

export interface AssetsState {
    alarmData: any | null;
    assetData: any | null;
}

export const initialState: AssetsState = {
    alarmData: null,
    assetData: null,
};

export const assetsFeature = createFeature({
    name: 'assets',
    reducer: createReducer(
        initialState,
        on(editAlarmSuccess, (state, { alarmData }) => ({ ...state, alarmData: alarmData })),
        on(loadAssetById, (state, { assetData }) => ({ ...state, assetData: assetData })),
        
        on(clearEditAlarm, (state) => ({ ...state, alarmData: null })),
        on(clearAssetById, (state) => ({ ...state, assetData: null })),
    ),
});

export const { name, reducer, selectAlarmData, selectAssetData } = assetsFeature;

export const selectAlarm = createSelector(selectAlarmData, (state) => state);
export const selectAsset = createSelector(selectAssetData, (state) => state);

